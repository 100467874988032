import React from "react";
import { Seo } from "../../components/Seo";
import {graphql} from 'gatsby';
import Cta from "../../components/Cta";
import Button from "../../components/Button";
import Stats from "../../components/Stats";
import OurTeam from "../../components/OurTeam";
import PageBanner from "../../components/PageBanner";
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const AboutUs = ({data}) => {
  const prismicData = data.prismicAboutUs.data;
  const team = prismicData.body.filter(e => e.slice_type === 'team_member');
  const teamData = {
    titles: prismicData.about_us_team_title_parts,
    team: team
  }
  const teamOps = prismicData.body1.filter(e => e.slice_type === 'team_member');
  const pageBannerData = {
    title: 'About Us',
    titleColor: '#ff6cbf',
    titleParts: prismicData.about_us_title_parts.map((i, k) => ({
      color: i.color,
      text: i.title
    })),
    backgroundColor: '#ffffff',
    image: prismicData.about_us_banner_image,
    imageStyle: 'rounded-3xl'
  }
  return (
    <main>
      <PageBanner {...pageBannerData} />
      <section className="bg-[#f8f8f8] py-16">
        <div className="xl:container mx-auto px-6 items-start text-center">
          <div className="lg:max-w-[85%] xl:max-w-[65%] mx-auto">
            {prismicData.about_us_paragraphs.map((i,k) => (
              <div className="text-2xl mb-4"
                key={k}
                dangerouslySetInnerHTML={{__html: i.paragraph.html}}></div>
            ))}
            <p className="text-2xl md:text-3xl font-black mt-8 mb-8">
              {prismicData.about_us_caption_parts.map((i,k) => (
                <span style={{color: i.color}}
                  key={k}>{i.caption} </span>
              ))}
            </p>
          </div>
          <div className="w-full xl:px-32 pt-16">
            <Stats data={prismicData.about_us_stats} />
          </div>
        </div>
      </section>
      <Cta bg="sourci-pink"
        image={prismicData.cta_banner_one_image}>
        <div className="text-center text-black w-full">
          <p className="text-4xl md:text-5xl font-black">Go ahead,</p>
          <p className="text-4xl md:text-5xl font-black mb-12">Hit the <span className="text-sourci-pink">start button</span></p>
          <Button label="Get in touch today"
            to="/contact"
            type="link"
            className="bg-sourci-teal hover:bg-sourci-teal-100 transition-colors text-white text-lg md:text-xl font-black" />
        </div>
      </Cta>
      <OurTeam data={teamData} />
      <section className="bg-white py-24">
        <div className="xl:container mx-auto text-center px-6">
          <h3 className="text-4xl md:text-5xl text-sourci-teal mb-20">Meet Your Team</h3>
          <div className="grid lg:grid-cols-4 gap-y-16 gap-x-16">
            {teamOps.map((i,k) => (
              <div key={i.id}>
                <div className="block mb-4 overflow-hidden rounded-3xl">
                  <GatsbyImage image={getImage(i.primary.team_member_photo)}
                    alt={i.primary.team_member_photo.alt || ''}
                    className="hover:scale-105 transition-transform duration-300" />
                </div>
                <div className="text-center">
                  <p className="font-black text-2xl">{i.primary.team_member_name}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="bg-white pt-16 pb-24">
        <div className="xl:container mx-auto text-center px-6">
          <h3 className="text-4xl md:text-5xl font-black text-black mb-20">Our <span className="text-sourci-pink">Core</span> Values</h3>
          <div className="grid lg:grid-cols-3 gap-x-12 gap-y-24 mt-[60px]">
          {prismicData.about_us_core_values.map((i,k) => (
            <div key={k}
              className="bg-[#f8f8f8] rounded-3xl px-8 pt-8 pb-12 relative">
              <div className="mt-[-60px] mb-[60px] h-[162px]">
                <GatsbyImage image={getImage(i.image.gatsbyImageData)}
                  alt={i.image.alt || ''}
                  className="max-w-[170px]" />
              </div>
              <p className="text-3xl md:text-4xl font-black mb-4">{i.title}</p>
              <div className="text-lg"
                dangerouslySetInnerHTML={{__html: i.content.html}}></div>
            </div>
          ))}
          </div>
        </div>
      </section>
      <Cta bg="sourci-teal"
        image={prismicData.cta_banner_two_image}>
        <div className="lg:col-start-2 text-center">
          <p className="text-4xl md:text-5xl font-black mb-4 text-black">We leave no stone unturned<br /> in pursuit of your growth.</p>
          <p className="text-4xl md:text-5xl font-black mb-8 text-white">We only grow when you<br /> grow, so let's get started.</p>
          <Button label="Get in touch today."
            to="/contact"
            type="link"
            className="bg-sourci-pink hover:bg-sourci-pink-100 transition-colors text-white text-lg md:text-xl font-black" />
        </div>
      </Cta>
    </main>
  )
}

export const query = graphql`
  query {
    prismicAboutUs {
      data {
        about_us_banner_image {
          gatsbyImageData(placeholder: BLURRED)
          alt
        }
        cta_banner_one_image {
          gatsbyImageData(placeholder: BLURRED)
          alt
        }
        cta_banner_two_image {
          gatsbyImageData(placeholder: BLURRED)
          alt
        }
        about_us_caption_parts {
          caption
          color
        }
        about_us_core_values {
          title
          content {
            html
          }
          image {
            gatsbyImageData(placeholder: BLURRED)
            alt
          }
        }
        about_us_paragraphs {
          paragraph {
            html
          }
        }
        about_us_stats {
          caption
          gradient_from
          gradient_to
          numeric_value
          prefix
          suffix
        }
        about_us_team_title_parts {
          color
          new_line
          title
        }
        about_us_title_parts {
          color
          title
        }
        body {
          ... on PrismicAboutUsDataBodyTeamMember {
            id
            slice_type
            primary {
              bio {
                html
              }
              coffee_preference
              email
              facebook_link {
                url
              }
              gender_pronoun
              headshot {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              instagram_link {
                url
              }
              linkedin_link {
                url
              }
              first_name
              last_name
              title
              youll_find_him {
                html
              }
              url_slug
            }
          }
        }
        body1 {
          ... on PrismicAboutUsDataBody1TeamMember {
            id
            primary {
              team_member_name
              team_member_photo {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            slice_type
          }
        }
        meta_description
        meta_title
      }
    }
  }
`

export default AboutUs;

export function Head({data}) {
  const prismicData = data.prismicAboutUs.data;
  return (
    <Seo title={prismicData.meta_title}
      description={prismicData.meta_description}
      canonical="/about-us" />
  )
}
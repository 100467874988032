import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from 'gatsby';
import Caret from "./icons/Caret";

const TeamHeadshots = ({team}) => {
  return (
    <>
      {team.map((i,k) => (
        <div key={i.id}>
          <Link className="block mb-4 overflow-hidden rounded-3xl"
            to={`/about-us/${i.primary.url_slug}`}>
            <GatsbyImage image={getImage(i.primary.headshot)}
              alt={i.primary.headshot.alt || ''}
              className="hover:scale-105 transition-transform duration-300" />
          </Link>
          <div className="text-center">
            <p className="font-black text-2xl">{i.primary.first_name} {i.primary.last_name}</p>
            <p className="text-xl text-sourci-pink">{i.primary.title}</p>
            <Link to={`/about-us/${i.primary.url_slug}`}>
              <Caret className="w-4 rotate-[270deg] mx-auto mt-2" />
            </Link>
          </div>
        </div>
      ))}
    </>
  )
}

export default TeamHeadshots;
import React from "react";
import TeamHeadshots from "./TeamHeadshots";

const OurTeam = ({data}) => {
  return (
    <div className="bg-[#f8f8f8] pt-16 pb-20">
      <div className="xl:container mx-auto grid lg:grid-cols-3 px-6 gap-y-16 gap-x-16">
        <div className="lg:col-span-3 text-right">
          <h1 className="text-3xl md:text-4xl font-black text-sourci-teal mb-8">Our Team</h1>
          <div className="w-full text-right xl:flex xl:justify-end">
            <p className="text-4xl md:text-5xl font-black xl:max-w-[80%]">
              {data.titles.map((i,k) => (
                <React.Fragment key={k}>
                  <span style={{color: i.color}}>{i.title} </span>
                  {i.new_line && <br />}
                </React.Fragment>
              ))}
            </p>
          </div>
        </div>
        <TeamHeadshots team={data.team} />
      </div>
    </div>
  )
}

export default OurTeam;
import React from "react";

const Caret = ({
  className,
  fill = '#00c9c9'
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.19 15.79" className={className}><defs><style></style></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><g id="Layer_1-3" data-name="Layer 1"><path style={{fill: fill}} d="M12.79,15.79h0a2.41,2.41,0,0,1-1.7-.71L.69,4.58a2.39,2.39,0,1,1,3.4-3.37L12.8,10,22.1.7a2.4,2.4,0,1,1,3.39,3.39l-11,11A2.44,2.44,0,0,1,12.79,15.79Z"/></g></g></g></svg>
  )
}

export default Caret;
import React from "react";

const Stats = ({data}) => {
  return (
    <div className="grid w-full grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-y-4">
      {data.map((i,k) => (
        <div className="bg-gradient-to-br w-[186px] h-[186px] lg:w-[206px] lg:h-[206px] rounded-full relative mx-auto"
          style={{
            '--tw-gradient-stops': `${i.gradient_from}, ${i.gradient_to}`
          }}
          key={k}>
          <div className="absolute w-[178px] h-[178px] lg:w-[198px] lg:h-[198px] bg-white top-[4px] left-[4px] rounded-full text-center flex flex-col items-center justify-center px-2">
            <div className="text-3xl lg:text-4xl font-black">{i.prefix}{i.numeric_value}{i.suffix}</div>
            <div className="text-base lg:text-lg font-black !leading-5">{i.caption}</div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default Stats;